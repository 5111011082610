<template>
  <div class="contact">
  	<div class="close-icon" @click="closePage">
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><line class="cls-1" x1="3" y1="2" x2="46" y2="45"/><line class="cls-1" x1="2" y1="46" x2="45" y2="3"/></g></g></svg>
	</div>
  	<div class="contact-copy">
  		<h1 class="animated fadeIn">Contact</h1>
  		<p class="animated fadeIn">Blood UTD</p>
  		<p class="animated fadeIn">320 Carlisle Street</p>
  		<p class="animated fadeIn">Balaclava</p>
  		<p class="animated fadeIn">Melbourne</p>
  		<p class="animated fadeIn">VIC 3183</p>

  		<div class="spacer-sm"></div>

  		<a class="animated fadeIn" href="mailto:grant@bloodutd.com,david@bloodutd.com">Contact us</a>
  		<!-- <a href="tel:0400966860" class="animated fadeInUp animation-delay-800">0400 966 860</a> -->

  		<!-- <div class="spacer-sm"></div> -->

  		<!-- <a href="mailto:david@bloodutd.com" class="animated fadeInUp animation-delay-900">david@bloodutd.com</a> -->
  		<!-- <a href="tel:0474726466" class="animated fadeInUp animation-delay-1000">0474 726 466</a> -->
  	</div>
  </div>
</template>

<script>

export default {
  name: 'contact',
  methods: {
  	closePage: function() {
  		this.$router.go(-1)
  	}
  }
}
</script>

<style lang="scss" scoped>
.contact {
	display: flex;
	align-items: center;
	justify-content: center;
	height: calc(100% - 140px);

    @media(min-width: 680px) {
		height: calc(100% + 100px);
    	margin-top: -100px;
    	margin-bottom: -100px;
    	margin-left: -100px;
    }

	@media(max-width: 680px) {
		padding: 20px;
	}

	.contact-copy {
		max-width: 400px;
		text-align: center;
		color: #f9f9f9;
		h1 {
			font-weight: 400;
			font-size: 1.6em;
			font-family: AkzidenzGrotesk-BoldExtended;
		}
		p {
			margin: 5px 0px;
			@media(min-width: 680px) {
				font-family: AkzidenzGrotesk-Medium;
			}
		}
		a {
			color: #f9f9f9;
			/*text-decoration: none;*/
			display: block;
			margin: 5px 0px;
			@media(min-width: 680px) {
				font-family: AkzidenzGrotesk-Medium;
			}
		}
		@media(max-width: 680px) {
			color: #373737;

			a {
				color: #373737;
			}
		}
	}

	.close-icon {
	    position: absolute;
	    top: 30px;
	    right: 100px;
	    font-size: 14px;
	    cursor: pointer;
	    width: 40px;
	    height: 40px;
	    z-index: 5;

	    @media(max-width: 680px) {
			display: none;
		}

	    svg {
	      width: 25px;
	    }
		.cls-1 {fill:none;stroke:#fff;stroke-linecap:round;stroke-miterlimit:10;stroke-width:4px;}
	}
}
</style>
